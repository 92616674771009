import React from 'react'
import ReactDOM from 'react-dom'
import App from '@walltowall/hillman-components'
import reportWebVitals from './reportWebVitals'

const root = document.getElementById('root')
const site = root.dataset.site
const grants = root.dataset.grants

ReactDOM.render(
  <React.StrictMode>
    <App site={site} grantTitle={grants} color='#3d7547' />
  </React.StrictMode>,
  root,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
